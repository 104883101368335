@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply h-full;
  }
}

@layer components {
  .container-header {
    @apply mx-auto my-6 px-4 max-w-[1200px] w-full rounded-[20px] border border-[#d2d6de];
  }

  .container-footer {
    @apply mx-auto my-6 px-4 max-w-[1200px] w-full;
  }

  body {
    @apply text-txt-base;
  }

  .container-content {
    @apply mx-auto px-4 max-w-[800px] w-full;
  }

  .custom-shadow {
    box-shadow: 0px 1px 2px 0px #1018280d, 0px -2px 0px 0px #0a0d120d inset,
      0px 0px 0px 1px #0a0d122e inset;
  }

  .btn-secondary {
    @apply bg-white 
           text-secondary 
           px-[25px] 
           py-[15px] 
           rounded-[15px] 
           border 
           border-secondary 
           shadow-md 
           hover:bg-gray-50 
           font-bold 
           text-base 
           transition-all 
           duration-200;
  }

  .btn-text {
    @apply text-primary 
           font-bold 
           hover:opacity-90 
           transition-opacity 
           duration-200 
           underline;
  }

  .btn-back {
    @apply text-primary 
           font-medium 
           hover:opacity-90 
           transition-opacity 
           duration-200 
           flex 
           items-center;
  }

  .form-input {
    @apply mt-1 
           block 
           w-full 
           rounded-lg 
           border-secondary 
           border 
           px-4 
           py-3
           text-base
           focus:border-outline 
           focus:ring-2 
           focus:ring-outline 
           focus:ring-opacity-50;
  }

  .form-label {
    @apply block 
           text-base 
           font-medium;
  }

  .form-checkbox {
    @apply h-5 
           w-5 
           rounded 
           border-secondary 
           focus:ring-2 
           focus:ring-orange-500;
  }

  .page-header {
    @apply bg-primary 
           shadow-md 
           p-4 
           text-white;
  }

  .carousel-dot {
    @apply h-2 
           w-2 
           rounded-full 
           mx-1 
           transition-colors 
           duration-200;
  }

  .carousel-dot-active {
    @apply bg-primary;
  }

  .carousel-dot-inactive {
    @apply bg-shadow;
  }

  /* Custom range slider styling */
  .loan-range-slider {
    @apply w-full appearance-none cursor-pointer;
    height: 5px;
    background: #e5e7eb;
    border-radius: 9999px;
    outline: none;
  }

  .loan-range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fe7551;
    cursor: pointer;
    margin-top: -11px; /* Center the thumb on the track */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 2px solid white;
  }

  .loan-range-slider::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fe7551;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 2px solid white;
  }

  .loan-range-slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: #e5e7eb;
    border-radius: 9999px;
  }

  .loan-range-slider::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: #e5e7eb;
    border-radius: 9999px;
  }

  /* Border gradient for Why Choose Afforda section */
  .border-gradient {
    @apply border-4 border-transparent bg-clip-padding;
    background-image: linear-gradient(to bottom, #fe7551, #ffc9bb);
    background-origin: border-box;
    border-image-slice: 1;
  }
}

.contact-form input[type="radio"] {
  visibility: hidden;
}